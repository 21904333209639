<template>
  <div>
    <BaseSidebar v-show="show" @input="onInput" style="z-index: 2" sticky-header>
      <template #action-before>
        <base-prop-tooltip
          tooltip="Refresh Geoscripts"
          color="primary"
          @click="refreshGeoscripts()"
        >
          <v-btn icon color="info">
            <v-icon>$segmentRefresh</v-icon>
          </v-btn>
        </base-prop-tooltip>
      </template>
      <div class="d-flex align-center">
        <h2 class="gs-header ml-2">Geoscripts</h2>
        <v-spacer></v-spacer>
        <v-text-field
          class="mx-1"
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <div v-if="geoscripts.length">
        <v-alert type="info" class="my-3" text v-if="!Object.keys(geoscriptComputed.groups).length"
          >No geoscripts found</v-alert
        >
        <BaseCollapse
          v-for="group in sort(Object.keys(geoscriptComputed.groups))"
          :key="'geoscript' + group"
          class="mt-2"
        >
          <template #title>
            {{ group }}
          </template>

          <template #action-after="{ value }">
            <v-icon class="ml-2" v-if="activeGroup(group) && !value" color="primary"
              >mdi-eye</v-icon
            >
          </template>

          <div class="gs-list mt-2">
            <div class="gs-list-item" v-for="gs in geoscriptComputed.groups[group]" :key="gs.id">
              <GeoscriptDetail
                :value="gs"
                :active="geoscriptActive(gs)"
                @click="onGsClick(gs)"
                @close="onInput(false)"
              />
            </div>
          </div>
        </BaseCollapse>
      </div>
      <div v-if="geometries.length">
        <h2 class="gs-header ml-2 mt-2">Geometries (styled)</h2>
        <v-alert
          type="info"
          class="my-3"
          text
          v-if="!Object.keys(geoscriptComputed.geometryGroups).length"
          >No geometries found</v-alert
        >
        <BaseCollapse
          v-for="group in sort(Object.keys(geoscriptComputed.geometryGroups))"
          :key="'geometry' + group"
          class="mt-2"
        >
          <template #title>
            {{ group }}
          </template>

          <template #action-after="{ value }">
            <v-icon class="ml-2" v-if="activeGeometryGroup(group) && !value" color="primary"
              >mdi-eye</v-icon
            >
          </template>

          <div class="gs-list mt-2">
            <div
              class="gs-list-item"
              v-for="gs in geoscriptComputed.geometryGroups[group]"
              :key="gs.id"
            >
              <GeoscriptDetail
                :value="gs"
                :active="activeGeometry(gs.id)"
                @click="toggleQueryLayer(gs.id)"
                @close="onInput(false)"
              />
            </div>
          </div>
        </BaseCollapse>
      </div>
      <div v-if="linkedGroups.length">
        <h2 class="gs-header ml-2 mt-2">Linked Groups</h2>
        <v-alert
          type="info"
          class="my-3"
          text
          v-if="!Object.keys(geoscriptComputed.linkedGroups).length"
          >No linked groups found</v-alert
        >
        <BaseCollapse
          v-for="group in sort(Object.keys(geoscriptComputed.linkedGroups))"
          :key="'linkedGroup' + group"
          class="mt-2"
        >
          <div class="gs-list mt-2">
            <div
              class="gs-list-item"
              v-for="gs in geoscriptComputed.linkedGroups[group]"
              :key="gs.id"
            >
              <GeoscriptDetail
                :value="gs"
                :active="activeGeometry(gs.id)"
                @click="toggleQueryLayer(gs.id)"
                @close="onInput(false)"
              />
            </div>
          </div>

          <template #title>
            {{ group }}
          </template>
          <template #action-after="{ value }">
            <v-icon class="ml-2" v-if="activeLinkedGroup(group) && !value" color="primary"
              >mdi-eye</v-icon
            >
          </template>
        </BaseCollapse>
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import { CMS } from '../../services/cms';
import { search, sort } from '../../utils';
import _ from 'lodash';
const cms = new CMS();
export default {
  props: {
    queryLayers: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      required: true
    },
    visibleLayers: {
      type: Array,
      required: true
    },
    visibleGeometries: {
      type: Array,
      required: true
    }
  },
  components: {
    GeoscriptDetail: () => import('./GeoscriptDetail.vue')
  },
  computed: {
    linkedGroups() {
      return this.$store.state.parcel.linkedGroupsGeometries;
    },
    queryLayersComputed() {
      let res = this.queryLayers || [];
      res = sort({
        items: res,
        generator: item => {
          return `${item.data.group || 'Z'}${item.data.key}`;
        }
      });
      return res;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    geoscriptComputed() {
      const groups = {};
      const geometryGroups = {};
      const linkedGroups = {};

      let filtered;
      if (this.search) {
        filtered = search({
          items: this.geoscripts,
          fields: ['data.key', 'data.description', 'data.comments', 'data.group'],
          query: this.search
        });
      } else {
        filtered = this.geoscripts;
      }

      filtered.forEach(gs => {
        const group = gs.data.group || 'other';
        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(gs);
      });

      if (this.search) {
        filtered = search({
          items: this.geometries,
          fields: ['data.key', 'data.description', 'data.comments'],
          query: this.search
        });
      } else {
        filtered = this.geometries;
      }

      filtered.forEach(gs => {
        const group = gs.data.group || 'other';
        if (!geometryGroups[group]) {
          geometryGroups[group] = [];
        }
        geometryGroups[group].push(gs);
      });

      if (this.search) {
        filtered = search({
          items: this.linkedGroups,
          fields: ['data.key', 'data.description', 'data.comments', 'data.group'],
          query: this.search
        });
      } else {
        filtered = this.linkedGroups;
      }

      filtered.forEach(gs => {
        const group = gs.data.group || 'other';
        if (!linkedGroups[group]) {
          linkedGroups[group] = [];
        }
        linkedGroups[group].push(gs);
      });

      return { groups, geometryGroups, linkedGroups };
    }
  },
  mounted() {
    this.loadGeoscripts();
    this.loadGeometries();
  },
  data() {
    return {
      menu: false,
      geoscripts: [],
      geometries: [],
      geoscriptGroups: {},
      localVisibility: {},
      search: ''
    };
  },
  methods: {
    activeGeometryGroup(group) {
      return this.geoscriptComputed.geometryGroups[group].some(l => {
        return this.visibleGeometries.includes(l.id);
      });
    },
    activeLinkedGroup(group) {
      return this.geoscriptComputed.linkedGroups[group].some(l => {
        return this.visibleGeometries.includes(l.id);
      });
    },
    activeGeometry(id) {
      return this.visibleGeometries.includes(id);
    },
    toggleQueryLayer(key) {
      this.$emit('toggleGeometry', key);
    },
    activeGroup(group) {
      const geoscripts = group
        ? this.geoscriptComputed.groups[group]
        : this.geoscriptComputed.geoscripts;
      try {
        for (const gs of geoscripts) {
          if (this.geoscriptActive(gs)) {
            return true;
          }
        }
      } catch (error) {
        console.warn(error);
      }
      return false;
    },
    onGsClick(gs) {
      const key = gs?.data?.key;
      if (this.geoscriptActive(gs)) {
        this.cancel(key);
      } else {
        this.execute(key);
      }
    },
    async refreshGeoscripts() {
      await this.loadGeoscripts();
      await this.loadGeometries();
      this.$bus.$emit('refreshGeoscripts');
    },
    sort(items) {
      return sort({
        items,
        generator: item => {
          if (item === 'other') {
            return 'Z';
          }
          return item;
        }
      });
    },
    onInput(value) {
      this.$emit('input', value);
    },
    execute(key) {
      this.$emit('execute', key);
    },
    cancel(key) {
      this.$emit('cancel', key);
    },
    startCase(str) {
      return _.startCase(str);
    },
    geoscriptActive(gs) {
      const key = gs?.data?.key;
      return (this.visibleLayers || []).indexOf(key) !== -1;
    },
    async loadGeoscripts() {
      const data = [];
      const res = await cms.get({ type: 'geoscript' });
      res.forEach(doc => {
        data.push({ id: doc.id, data: doc.data() });
      });
      this.geoscripts = [];
      this.geoscripts = sort({ items: data, field: 'data.key' });
    },
    async loadGeometries() {
      let geometries = [];

      try {
        const res = await cms.get({ type: 'geojson' });
        res.forEach(doc => {
          const detail = doc.data();
          const widgetData = { id: doc.id, data: detail, visible: false };
          let isLinkedGroup = false;

          try {
            const splitted = String(widgetData.data.group || '').split('/');
            const group = String(splitted[0] || '')
              .toLowerCase()
              .trim();
            const linkedGroup = this.$store.state.parcel.linkedGroups.filter(
              i =>
                String(i.value || '')
                  .toLowerCase()
                  .trim() === group
            )[0];
            if (linkedGroup) {
              isLinkedGroup = true;
            }
          } catch (error) {
            console.warn(error);
            //
          }

          if (!isLinkedGroup) {
            geometries.push(widgetData);
          }
        });
      } catch {
        //
      }

      geometries = sort({ items: geometries, field: 'data.key' });
      this.geometries = geometries;
    }
  }
};
</script>

<style scoped lang="scss">
.gs-header {
  font-size: 1.1rem;
  font-weight: 500;
}
</style>
