import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseSidebar',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticStyle:{"z-index":"2"},attrs:{"sticky-header":""},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"action-before",fn:function(){return [_c('base-prop-tooltip',{attrs:{"tooltip":"Refresh Geoscripts","color":"primary"},on:{"click":function($event){return _vm.refreshGeoscripts()}}},[_c(VBtn,{attrs:{"icon":"","color":"info"}},[_c(VIcon,[_vm._v("$segmentRefresh")])],1)],1)]},proxy:true}])},[_c('div',{staticClass:"d-flex align-center"},[_c('h2',{staticClass:"gs-header ml-2"},[_vm._v("Geoscripts")]),_c(VSpacer),_c(VTextField,{staticClass:"mx-1",attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.geoscripts.length)?_c('div',[(!Object.keys(_vm.geoscriptComputed.groups).length)?_c(VAlert,{staticClass:"my-3",attrs:{"type":"info","text":""}},[_vm._v("No geoscripts found")]):_vm._e(),_vm._l((_vm.sort(Object.keys(_vm.geoscriptComputed.groups))),function(group){return _c('BaseCollapse',{key:'geoscript' + group,staticClass:"mt-2",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(group)+" ")]},proxy:true},{key:"action-after",fn:function(ref){
var value = ref.value;
return [(_vm.activeGroup(group) && !value)?_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary"}},[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"gs-list mt-2"},_vm._l((_vm.geoscriptComputed.groups[group]),function(gs){return _c('div',{key:gs.id,staticClass:"gs-list-item"},[_c('GeoscriptDetail',{attrs:{"value":gs,"active":_vm.geoscriptActive(gs)},on:{"click":function($event){return _vm.onGsClick(gs)},"close":function($event){return _vm.onInput(false)}}})],1)}),0)])})],2):_vm._e(),(_vm.geometries.length)?_c('div',[_c('h2',{staticClass:"gs-header ml-2 mt-2"},[_vm._v("Geometries (styled)")]),(!Object.keys(_vm.geoscriptComputed.geometryGroups).length)?_c(VAlert,{staticClass:"my-3",attrs:{"type":"info","text":""}},[_vm._v("No geometries found")]):_vm._e(),_vm._l((_vm.sort(Object.keys(_vm.geoscriptComputed.geometryGroups))),function(group){return _c('BaseCollapse',{key:'geometry' + group,staticClass:"mt-2",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(group)+" ")]},proxy:true},{key:"action-after",fn:function(ref){
var value = ref.value;
return [(_vm.activeGeometryGroup(group) && !value)?_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary"}},[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"gs-list mt-2"},_vm._l((_vm.geoscriptComputed.geometryGroups[group]),function(gs){return _c('div',{key:gs.id,staticClass:"gs-list-item"},[_c('GeoscriptDetail',{attrs:{"value":gs,"active":_vm.activeGeometry(gs.id)},on:{"click":function($event){return _vm.toggleQueryLayer(gs.id)},"close":function($event){return _vm.onInput(false)}}})],1)}),0)])})],2):_vm._e(),(_vm.linkedGroups.length)?_c('div',[_c('h2',{staticClass:"gs-header ml-2 mt-2"},[_vm._v("Linked Groups")]),(!Object.keys(_vm.geoscriptComputed.linkedGroups).length)?_c(VAlert,{staticClass:"my-3",attrs:{"type":"info","text":""}},[_vm._v("No linked groups found")]):_vm._e(),_vm._l((_vm.sort(Object.keys(_vm.geoscriptComputed.linkedGroups))),function(group){return _c('BaseCollapse',{key:'linkedGroup' + group,staticClass:"mt-2",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(group)+" ")]},proxy:true},{key:"action-after",fn:function(ref){
var value = ref.value;
return [(_vm.activeLinkedGroup(group) && !value)?_c(VIcon,{staticClass:"ml-2",attrs:{"color":"primary"}},[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"gs-list mt-2"},_vm._l((_vm.geoscriptComputed.linkedGroups[group]),function(gs){return _c('div',{key:gs.id,staticClass:"gs-list-item"},[_c('GeoscriptDetail',{attrs:{"value":gs,"active":_vm.activeGeometry(gs.id)},on:{"click":function($event){return _vm.toggleQueryLayer(gs.id)},"close":function($event){return _vm.onInput(false)}}})],1)}),0)])})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }